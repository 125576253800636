import { useEffect } from 'react';
import logo from './transparent.svg';
import './App.css';


function App() {
  useEffect(() => {
    // Ensure the script is only appended once
    const scriptAlreadyLoaded = document.getElementById('launchlist-widget-script');
    if (!scriptAlreadyLoaded) {
      const script = document.createElement('script');
      script.id = 'launchlist-widget-script';
      script.src = 'https://getlaunchlist.com/js/widget.js';
      script.defer = true;
      document.body.appendChild(script);
    }
  }, []);

  return (
    <div className="App">
      <header className="App-header">
        <h1>Welcome to</h1>
        <img src={logo} alt="Horsebox Logo" className="App-logo" />
        <p className="App-explanation">
          Join our waitlist to get early access to the Horsebox platform!
        </p>
        <p className="App-explanation">
          Stay up to date with all things Horsebox - Join our community now!
        </p>
      </header>
      <div className="launchlist-widget" data-key-id="nwsAVM" data-height="180px"></div>
      <footer className="App-footer">
        <p className="App-contact">
            Questions? Email us at <a href="mailto:info@horsebox.io">info@horsebox.io</a>
        </p>
        <a href="https://www.instagram.com/horsebox.io" target="_blank" rel="noopener noreferrer" className="App-instagram">
          <i className="fab fa-instagram"></i> Follow us on Instagram
        </a>
      </footer>
    </div>
  );
}

export default App;